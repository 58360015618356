<template>
  <NuxtPwaManifest />
  <main>
    <notification/>

    <navigation-guest-top-nav/>

    <div class="min-h-screen h-full">
      <slot/>
    </div>
  </main>

  <navigation-guest-footer/>
</template>
